import React from "react";

import PageLayout from "../components/page-layout";

import * as style from "./privacy.module.css";

export default function ImprintPage() {
  return (
    <PageLayout mainClassName={style.main}>
      <div>
        <h1>Datenschutzrichtlinie</h1>
        <p>(April 2021)</p>
        <p>
          <br />
          <br />
          Alle Daten werden verschlüsselt auf unserem deutschen Server
          gespeichert und auf Wunsch nach dem Auftrag wieder gelöscht.
        </p>
        <p>
          Wenn Sie eine Bewerbung bei uns erstellen lassen oder anderweitig
          unsere Website nutzen, erfassen und verarbeiten wir Ihre
          personenbezogenen Daten. Das vorliegende Dokument erläutert Ihnen,
          welche personenbezogenen Daten wir über Sie erfassen, wie wir diese
          erfassen, wofür wir sie nutzen und welche Rechte Sie in Bezug auf Ihre
          personenbezogenen Daten haben.
        </p>
        <p>
          Wenn wir von CV Abroad-Service, „wir“, „uns“ oder „unsere“ sprechen,
          meinen wir damit <b>den Einzelunternehmer Herr Elshad Shirinov</b>,
          der die Leistung anbietet, und verbundenen Unternehmen. Wir verwenden
          des Weiteren „Richtlinie“, um auf diese Datenschutzrichtlinie zu
          verweisen, sowie „Website “, um auf unsere Anwendungen und alle
          Websites von CV Abroad-Leistung zu verweisen.
        </p>
        <p>
          Einzelunternehmer Elshad Shirinov ist die juristische Person, die für
          die Verarbeitung Ihrer personenbezogenen Daten verantwortlich ist.
        </p>
        <h2>
          1 Personenbezogene Daten – was wir erfassen und wofür wir diese Daten
          verwenden
        </h2>
        <h3>1.1 Stammdaten</h3>
        <p>
          Wenn Sie ein Kundenkonto erstellen, generieren wir automatisch einen
          persönlichen Account für Sie bei uns und erfassen die nachstehend
          aufgeführten personenbezogenen Daten über Sie (die „Stammdaten“):
        </p>
        <ul>
          <li>Ihren Vor- & Nachnamen</li>
          <li>Ihre E-Mailadresse</li>
        </ul>
        <p>
          Diese Angaben sind verpflichtend, was bedeutet, dass Sie ohne diese
          kein Konto erstellen können. Falls Sie eine Bestellung bei uns
          aufgeben möchten. So werden neben den für den Auftrag relevanten Daten
          noch folgende Angaben benötigt und erfasst:
        </p>
        <ul>
          <li>Ihre Adresse</li>
        </ul>
        <h3>1.6 Cookies</h3>
        <p>
          Unsere Website verwendet Cookies. Cookies identifizieren Ihren
          Computer oder Ihr Gerät, nicht Sie, einen bestimmten Nutzer, und
          werden für verschiedene Zwecke verwendet.
        </p>
        <p>
          Weiter unten können Sie mehr darüber erfahren, welche Cookies die
          Website für welche Zwecke verwendet.
        </p>
        <h3>1.7 Ihre IP-Adresse, Ihre Browsereinstellungen und Ihr Standort</h3>
        <p>
          Wenn Sie die Website aufrufen, registrieren wir die IP-Adresse und die
          Browsereinstellungen Ihres Computers. Die IP-Adresse ist eine
          numerische Kennung des Computers, die zum Aufrufen der Website genutzt
          wird. Die Browsereinstellungen können Angaben zu dem von Ihnen
          verwendeten Browsertyp, der Browsersprache und der Zeitzone enthalten.
          Wir erfassen diese Informationen, damit wir in Fällen missbräuchlicher
          Nutzung bzw. rechtswidriger Handlungen in Verbindung mit dem Aufrufen
          oder der Nutzung unserer Website den dafür benutzten Computer
          zurückverfolgen können. Wir verwenden die IP-Adresse auch zur
          Bestimmung Ihres ungefähren Standorts (auf Stadtebene) und damit wir
          wissen, dass es sich bei Ihrem Account um keinen Fake-Account handelt.
        </p>
        <h3>1.8 Newsletter und Übersichts-E-Mails</h3>
        <p>
          Wir verschicken keine Newsletter und Werbemails. Es werden lediglich
          für den Auftrag relevante Mails verschickt.
        </p>
        <h3>
          1.9 Für welche Zwecke verwenden wir Ihre personenbezogenen Daten?
        </h3>
        <p>Wir verwenden die von Ihnen erhaltenen Daten, um:</p>
        <ul>
          <li>
            Ihnen unsere Dienste bereitzustellen, einschließlich der Erstellung
            der Bewerbung,
          </li>
          <li>
            Sie als registrierten Nutzer zu identifizieren, wenn Sie sich auf
            unserer Website einloggen und diese erneut besuchen,
          </li>
          <li>die Website und unsere Dienste zu verbessern,</li>
          <li>
            Ihre Fragen zu beantworten und entsprechende Kundendienstleistungen
            anzubieten,
          </li>
          <li>
            Sie zu informieren, wenn wir noch weitere Unterlagen benötigen
          </li>
          <li>
            verschiedene interne geschäftliche Maßnahmen durchzuführen, z. B.
            Datenanalyse, Kontrollen, Überwachung und{" "}
          </li>
          vorbeugende Maßnahmen zum Schutz gegen Betrugsversuche, Entwicklung
          neuer Produkte und Dienste, Verbesserung oder Überarbeitung der
          Website oder unserer Dienste, Ermittlung von Nutzungstrends,
          Ermittlung der Wirksamkeit unserer Werbekampagnen sowie Durchführung
          und Ausdehnung unserer Geschäftstätigkeiten,
          <li>
            die Einhaltung gesetzlicher Vorschriften und Verfahren sowie die
            Erfüllung der Forderungen öffentlicher und{" "}
          </li>
          staatlicher Behörden, der geltenden Branchenstandards und unserer
          internen Richtlinien zu gewährleisten,
          <li>
            unsere Geschäftstätigkeit bzw. die unserer verbundenen Unternehmen
            zu schützen,
          </li>
          <li>
            unsere Rechte, unsere Privatsphäre, unsere Sicherheit bzw. unser
            Eigentum und/oder jene unserer verbundenen{" "}
          </li>
          Unternehmen, von Ihnen oder anderen zu schützen,
          <li>
            uns die Inanspruchnahme möglicher Rechtsbehelfe sowie die Begrenzung
            etwaiger Schäden, die uns möglicherweise{" "}
          </li>
          entstehen, zu erlauben.
        </ul>
        <p>
          Wir behalten uns vor, diese Daten auch in anderer Weise nutzen, für
          die wir zum Zeitpunkt der Erfassung gesonderte Informationen
          bereitstellen.
        </p>
        <h3>
          1.10 Auf welcher rechtlichen Grundlage verarbeiten wir Ihre
          personenbezogenen Daten?
        </h3>
        Wir müssen Ihre personenbezogenen Daten verarbeiten, um:
        <ul>
          <li>
            unseren Vertrag mit Ihnen zu erfüllen (siehe Artikel 6.1.b DSGVO),
          </li>
          <li>
            unsere eigenen legitimen Geschäftsinteressen in Bezug auf den
            Betrieb der Webseite und die Bereitstellung{" "}
          </li>
          unserer Dienste zu verfolgen oder um legitime Interessen Dritter zu
          verfolgen, solange Ihre Interessen und Grundrechte nicht diese
          Interessen außer Kraft setzen (siehe Artikel 6.1.f DSGVO),
          <li>
            bei Bedarf Rechtsansprüche geltend zu machen, wahrzunehmen oder zu
            verteidigen (siehe Artikel 9.2.f DSGVO).
          </li>
        </ul>
        Einige dieser Gründe für die Verarbeitung Ihrer personenbezogenen Daten
        überschneiden sich, daher kann es mehrere Gründe geben, die die
        Verarbeitung Ihrer personenbezogenen Daten durch uns rechtfertigen. In
        den beschränkten Umständen, für die Sie uns ausdrücklich Ihre Zustimmung
        für die Verarbeitung Ihrer personenbezogenen Daten gegeben haben (siehe
        Artikel 6.1.a DSGVO), steht es Ihnen frei, Ihre Zustimmung jederzeit zu
        widerrufen. Bitte beachten Sie jedoch, dass wir möglicherweise das Recht
        haben, weiterhin Ihre Informationen zu verarbeiten, wenn dies laut einer
        der anderen oben genannten rechtlichen Grundlagen zulässig ist. Sie
        haben das Recht, der Art und Weise zu widersprechen, in der wir Ihre
        personenbezogenen Daten verarbeiten, oder uns zu bitten, die
        Verarbeitung einzuschränken.
        <h2>2 Offenlegung personenbezogener Daten</h2>
        <p>
          Wir legen Ihre personenbezogenen Daten gegenüber den folgenden
          Parteien und unter den folgenden Umständen offen:
        </p>
        <ul>
          <li>
            Drittparteien, u. a. beauftragte Anbieter, Berater und sonstige
            Dienstleister, um diesen die Erbringung von Leistungen in unserem
            Auftrag zu ermöglichen,
          </li>
          <li>
            Drittparteien im Zusammenhang mit der Durchsetzung unserer 
            Richtlinien,
          </li>
          <li>
            Drittparteien, um unsere Geschäftstätigkeit bzw. die unserer
            verbundenen Unternehmen zu schützen,
          </li>
          <li>
            Drittparteien, um uns die Inanspruchnahme möglicher Rechtsbehelfe
            sowie die Begrenzung von Schäden, die uns möglicherweise entstehen,
            zu erlauben,
          </li>
          <li>
            Drittparteien, damit wir mutmaßliche oder tatsächliche unzulässige
            Handlungen, wie u. a. Betrug oder die missbräuchliche Nutzung
            unserer Website, untersuchen, diesen vorbeugen oder dagegen Schritte
            einleiten können,
          </li>
          <li>
            Drittparteien im Falle von Umstrukturierungen, Fusionen, Übernahmen,
            Verkäufen, Joint Ventures, Abtretungen, Übertragungen oder sonstigen
            Veräußerungen des gesamten oder Teilen unseres Geschäfts bzw.
            unserer Vermögenswerte (einschließlich in Verbindung mit etwaigen
            Insolvenz- bzw. ähnlichen Verfahren).
          </li>
        </ul>
        <h2>3 Für den Datenschutz verantwortliche Stelle</h2>
        Daten, für die wir die für den Datenschutz verantwortliche Stelle sind
        Wir sind die für den Datenschutz verantwortliche Stelle für die
        Stammdaten, die Sie bei der Erstellung oder Bearbeitung Ihres Profils
        eingeben; hierzu zählen u. a. Ihr Name, Ihr Passwort und Ihre
        E-Mail-Adresse sowie die Registrierung Ihrer IP-Adresse. Wir sind zudem
        die für den Datenschutz verantwortliche Stelle für Daten, die an andere
        Dienste weitergegeben werden. Die Erfassung Ihrer Daten durch uns
        unterliegt den deutschen Datenschutzgesetzen.
        <h2>4 Links zu Websites</h2>
        Unsere Website enthält Links zu anderen Websites. Die Aufnahme solcher
        Links bedeutet keinesfalls, dass wir diese Websites empfehlen. Wir
        kontrollieren keine Inhalte solcher Drittpartei-Websites und übernehmen
        keine Verantwortung für die Drittpartei, deren Geschäftsprinzipien oder
        Praktiken. Wir empfehlen Ihnen ausdrücklich, die Datenschutzbestimmungen
        dieser Drittpartei-Websites zu prüfen, da sich deren Vorgehen in Bezug
        auf die Erfassung, Handhabung und Verarbeitung personenbezogener Daten
        von unserem Vorgehen unterscheiden kann.
        <h2>
          5 Auftragsverarbeiter und Transfer personenbezogener Daten in Regionen
          außerhalb der EU
        </h2>
        Wir verwenden externe Unternehmen für die Aufrechterhaltung des
        technischen Betriebs der Website und unserer Dienste. Diese Unternehmen
        sind Auftragsverarbeiter für personenbezogene Daten, für die wir die für
        den Datenschutz verantwortliche Stelle sind. Durch die Zustimmung zu
        dieser Richtlinie erklären Sie sich damit einverstanden, dass wir auch
        die Daten, für die Sie die für den Datenschutz verantwortliche Stelle
        sind, durch die gleichen Auftragsverarbeiter verarbeiten lassen können.
        Wir haben Datenverarbeitungsverträge mit diesen Auftragsverarbeitern
        geschlossen und diese besagen, dass sie ausschließlich gemäß unseren
        Anweisungen handeln dürfen. Durch die Zustimmung zu dieser Richtlinie
        bevollmächtigen Sie uns, den Auftragsverarbeitern derartige Anweisungen
        zur Datenverarbeitung gemäß der Richtlinie und für den Zweck der
        Website-Nutzung zu geben. Die Autragsverarbeiter haben angemessene
        technische und organisatorische Maßnahmen ergriffen, damit Informationen
        nicht versehentlich oder illegal vernichtet werden, verloren gehen,
        beschädigt, gegenüber nicht autorisierten Personen offengelegt,
        missbraucht oder auf andere Weise verarbeitet werden, die im Widerspruch
        zu Datenschutzgesetzen steht. Auf Ihren Wunsch hin – und möglicherweise
        gegen eine Vergütung zu den zum jeweiligen Zeitpunkt geltenden
        stündlichen Tarifen des Auftragsverarbeiters – muss der
        Auftragsverarbeiter Ihnen Informationen zukommen lassen, die ausreichend
        belegen, dass die oben genannten technischen und organisatorischen
        Sicherheitsmaßnahmen auch tatsächlich ergriffen worden sind. Einige
        dieser Auftragsverarbeiter und Drittpartei-Dienstanbieter sind außerhalb
        der Europäischen Union, wie beispielsweise in den USA, ansässig. Sie
        geben uns Ihre Zustimmung, Auftragsverarbeiter in unsicheren
        Drittländern einzusetzen, vorausgesetzt, dass es einen rechtlichen
        Rahmen gibt, der den Transfer Ihrer personenbezogenen Daten regelt und
        einen ausreichenden Schutz dieser Daten gewährleistet, beispielsweise
        wenn der Auftragsverarbeiter Teil des EU-US-Datenschutzschilds ist.
        <h2>6 Datenaufbewahrung</h2>
        Wir bewahren die Stammdaten und anderen personenbezogenen Daten, die Sie
        bereitstellen, einschließlich Ihrer Bewerbungen, solange auf, wie Sie
        einen Account haben oder wie erforderlich ist, um Ihnen unsere Dienste
        bereitzustellen. Wenn Sie Ihren Account löschen, beachten Sie bitte,
        dass all Ihre Unterlagen ebenfalls gelöscht werden. Wir löschen diese
        Daten auf Ihren Wunsch, und wir speichern lediglich ein Protokoll mit
        den folgenden Daten: Rechnungs-Dokument und das Datum der Löschung Ihres
        Accounts. Wir bewahren dieses Protokoll 3 Jahre auf. Alle weiteren Daten
        werden gelöscht.
        <h2>7 Sicherheitsmaßnahmen</h2>
        Wir setzen angemessene organisatorische, technische und administrative
        Maßnahmen zum Schutz Ihrer personenbezogenen Daten innerhalb unserer
        Organisation ein, und wir überprüfen unsere Systeme regelmäßig auf
        Schwachstellen. Jedoch können wir, da das Internet kein zu 100% sicheres
        Umfeld darstellt, die Sicherheit der Daten, die Sie uns übermitteln,
        nicht gewährleisten oder garantieren. Wir arbeiten beständig an der
        Verbesserung unserer Sicherheitsmaßnahmen, und wir werden bei Neuerungen
        diese Informationen kontinuierlich aktualisieren. Alle Daten werden
        verschlüsselt gespeichert und genügen höchsten Sicherheitsansprüchen.
        <h2>8 Cookies</h2>
        Die Website verwendet Cookies und vergleichbare Technologien
        („Cookies“). Mit der Nutzung unserer Website akzeptieren Sie, dass wir
        Cookies in der unten beschriebenen Weise verwenden.
        <h3>8.1  Welche Cookie-Typen verwenden wir?</h3>
        Cookies sind kleine Informationseinheiten, die die Website auf der
        Festplatte Ihres Computers, Ihrem Tablet oder Smartphone ablegt. Bitte
        beachten, dass HTML5 das Merkmal Web Storage eingeführt hat, das mit
        Cookies vergleichbar ist und das wir aus diesem Grund im Folgenden als
        Cookie betrachten. Cookies enthalten Informationen, die die Website für
        die verbesserte Effizienz der Kommunikation zwischen Ihnen und Ihrem
        Webbrowser nutzt. Cookies identifizieren Ihren Computer oder Ihr Gerät
        und nicht Sie als einen bestimmten Nutzer. Wir setzen Session Cookies,
        permanente Cookies, Session Cookies für HTML5 sessionStorage und HTML5
        localStorage sowie HTML5 sessionStorage-Objekte, die temporär sind und
        nach Schließen Ihres Internetbrowsers gelöscht werden. Permanente
        Cookies werden für eine längere Dauer gespeichert und verbleiben auf
        Ihrem Computer, bis sie gelöscht werden. Permanente Cookies laufen ab
        oder löschen sich nach einer gewissen Zeit selbst, was je nach Cookie
        festgelegt wird, werden aber jedes Mal erneuert, wenn Sie die Website
        aufrufen. HTML5 localStorage-Objekte sind dauerhaft und verbleiben auf
        Ihrem Computer, bis sie gelöscht werden.
        <h3>8.2 Wofür verwenden wir Cookies?</h3>
        Wir verwenden Cookies für folgende Zwecke: Generierung von Statistiken
        Für die Messung des Website-Traffics, wie beispielsweise die Zahl der
        Website-Besucher, von welcher Domain die Besucher kommen, welche Seiten
        sie auf der Website besuchen und in welchen geografischen Gebieten sich
        die Besucher befinden. Überwachung der Website-Performance und Ihrer
        Nutzung unserer Website Für die Überwachung der Website-Performance.
        Anmeldeverfahren und Verbesserung der Funktionalität unserer Website Für
        die Optimierung des Nutzererlebnisses auf der Website, wozu eine
        Erinnerungsfunktion für Ihren Nutzernamen und Ihr Passwort zählt, wenn
        Sie die Website erneut besuchen, sowie eine Erinnerungsfunktion für
        Ihren Browser und die bevorzugten Einstellungen (z. B. Ihre bevorzugte
        Sprache). Qualitätssicherung Für die Sicherung der Qualität von
        Bewertungen und für die Prävention von Missbrauch und Unregelmäßigkeiten
        im Zusammenhang mit der Erteilung von Bestellungen. Gezielte
        Werbeanzeigen Für die Anzeige relevanter Werbeanzeigen auf der Website,
        einschließlich auf Interessen basierender Werbeanzeigen, von denen wir
        denken, dass diese für Sie von Interesse sein könnten. Wir nutzen
        Drittparteien zur Bereitstellung dieser Werbeanzeigen, wenn Sie die
        Website und andere Internetseiten besuchen. Diese Drittparteien
        verwenden möglicherweise Cookies allein oder in Verbindung mit
        Web-Beacons und sonstigen Trackingtechnologien zur Erfassung von
        Informationen über Ihre Nutzung unserer Website und anderer
        Internetseiten.
        <h3>8.3 Cookies von Drittparteien</h3>
        <ul>
          <li>
            Cookies von Drittparteien werden von Websites Dritter gesetzt, nicht
            von unserer Website. Wenn Sie unsere Website besuchen, können die
            folgenden Cookies von Drittparteien gesetzt sein:
          </li>
          <li>
            Google-Cookies, die gesetzt werden, wenn Sie sich über Google auf
            unserer Website einloggen
          </li>
          <li>
            Google AdSense-Cookies, die gesetzt werden, wenn relevante gezielte
            Werbung auf unserer Website gezeigt wird. Gewisse Cookies können als
            DoubleClick gesetzt sein, was Teil von Google ist.
          </li>
        </ul>
        <h3>8.4 Löschen von Cookies</h3>
        Sie können die Cookies löschen, die sich bereits auf Ihrem Gerät
        befinden. In der Regel können Sie Cookies in den Bereichen Datenschutz
        oder Verlauf löschen, die unter Einstellungen oder Optionen im Browser
        verfügbar sind. Bei den meisten Browsern kann man diese über das
        Tastaturkürzel Steuerungstaste + Umschalttaste + Entfernen oder, falls
        Sie einen Mac benutzen, über Befehlstaste +  Umschalttaste +
        Rückschritttaste aufrufen. Wenn Sie die Cookies unserer Website nicht
        annehmen, kann das die Nutzung unserer Website beeinträchtigen, und
        möglicherweise können Sie nicht auf alle dort vorhandenen Funktionen
        zugreifen.
        <h2>
          9 Zugriff auf und Einsicht in die personenbezogenen Daten, die wir
          über Sie führen
        </h2>
        Wenn Sie einen Account bei CV Abroad-Service haben, können Sie sich in
        Ihren Account einloggen und die Daten einsehen, die wir über Sie führen,
        einschließlich Ihrer Bewerbung und warum wir diese Daten haben. Sie
        können alle Daten anfordern, die wir von Ihnen erheben. Wenn Sie keinen
        Account haben, können Sie sich per E-Mail an uns wenden und
        Informationen über Ihre personenbezogenen Daten erfragen. Nach Erhalt
        Ihrer Anfrage teilen wir Ihnen mit, welche personenbezogenen Daten wir
        über Sie führen, wie wir diese Daten erfassen, den Zweck, für den wir
        Ihre personenbezogenen Daten verarbeiten, und wem wir Ihre
        personenbezogenen Daten mitteilen.
        <h2>
          10 Herunterladen Ihrer personenbezogenen Daten (Datenübertragbarkeit)
        </h2>
        Wenn Sie einen Account bei uns haben, können Sie die personenbezogenen
        Daten, die Sie uns zur Verfügung gestellt haben, einschließlich Ihrer
        Bewerbung, herunterladen. Sie können hier mehr über das Herunterladen
        Ihrer personenbezogenen Daten erfahren.
        <h2>11 Korrektur und Löschen Ihrer personenbezogenen Daten</h2>
        Wenn Stammdaten oder andere personenbezogene Informationen, die wir in
        unserer Kapazität als Datenverantwortlicher über Sie führen, falsch oder
        irreführend sind, können Sie die meisten dieser Daten selbst über Ihren
        Account korrigieren. Wir empfehlen Ihnen, dass Sie diese Korrektur(en)
        selbstständig vornehmen. Wir helfen Ihnen aber auch gerne bei der
        Korrektur Ihrer Daten. Sie können jederzeit sämtliche Inhalte und
        personenbezogenen Daten auf der Website korrigieren oder löschen. Wenn
        sich Ihre personenbezogenen Daten ändern oder wenn Sie nicht länger auf
        der Website erscheinen möchten, können Sie die Daten aktualisieren oder
        löschen, indem Sie sich in Ihren Account einloggen. Wenn Ihr Account
        gelöscht wird, werden alle mit Ihrem Account verbundenen Daten gelöscht,
        einschließlich der Stammdaten und Bewerbungen auf der Website. Wir
        behalten uns das Recht vor, den Zugang zu Ihrem Account zu sperren
        und/oder Ihren Account zu löschen, wenn der Account oder der mit Ihrem
        Account verbundene Inhalt bzw. Ihre Bewertung(en) auf der Website
        unserer Einschätzung nach diskriminierend, rassistisch, sexuell
        anstößig, unethisch, drohend, aggressiv, schikanierend ist/sind bzw.
        anderweitig die geltenden Gesetze, Rechte Dritter verletzt/verletzen
        oder nicht mit dem Zweck der Website vereinbar ist/sind. Wenn wir den
        Zugang sperren oder Ihren Account löschen, werden wir Sie über die
        Gründe für die Sperrung oder Löschung Ihres Accounts informieren, indem
        wir eine E-Mail an die Adresse senden, die Sie beim Einrichten Ihres
        Accounts angegeben haben.
        <h2>12 Weitere Rechte</h2>
        <ul>
          Neben den oben aufgeführten Rechten über Ihre personenbezogenen Daten
          haben Sie noch die folgenden Rechte:
          <li>
            Sie haben auch das Recht, der Verarbeitung Ihrer personenbezogenen
            Daten zu widersprechen und zu verlangen, die Verarbeitung Ihrer
            personenbezogenen Daten zu beschränken.
          </li>
          <li>
            Wenn unsere Verarbeitung Ihrer personenbezogenen Daten auf Ihrer
            Zustimmung basiert, können Sie Ihre Zustimmung jederzeit widerrufen.
            Der Widerruf Ihrer Zustimmung wirkt sich nicht auf die
            Rechtmäßigkeit der Datenverarbeitung aus, die vor dem Widerruf Ihrer
            Zustimmung erfolgte.
          </li>
        </ul>
        Unter bestimmten Umständen können diese Rechte eingeschränkt werden oder
        von Bedingungen abhängig sein. So hängt Ihr Recht auf
        Datenübertragbarkeit z. B. in einem bestimmten Fall von den konkreten
        Umständen des Verarbeitungsvorgangs ab.
        <h2>13 Informationen über Kinder</h2>
        Unsere Website ist nicht für Kinder gedacht. Wenn Sie erfahren, dass ein
        Kind jünger als 13 Jahre uns seine personenbezogenen Daten überlassen
        hat, setzten Sie sich bitte mit uns in Verbindung.
        <h2>14 Änderungen dieser Richtlinie</h2>
        Wir behalten uns das Recht auf Änderungen dieser Richtlinie vor. Das
        Datum am Anfang dieser Richtlinie zeigt an, wann diese zuletzt geändert
        wurde. Sollten wir wesentliche Änderungen an dieser Richtlinie
        vornehmen, teilen wir dies auf unserer Website oder auf andere Weise
        mit, damit Sie Gelegenheit haben, die Änderungen zu prüfen, bevor diese
        in Kraft treten. Sollten Sie den Änderungen nicht zustimmen, können Sie
        Ihren Account schließen. Ihre weitere Nutzung unserer Website nach
        Veröffentlichung der Änderungen oder Mitteilung über geplante Änderungen
        an der Richtlinie bedeutet, dass Sie diese akzeptieren und der
        aktualisierten Richtlinie zustimmen.
      </div>
    </PageLayout>
  );
}
